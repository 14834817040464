/* Top bar - Phone & Message/Email links */

import { Flex, HStack, Icon, Link, StackDivider, Text } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { FaEnvelope, FaPhone } from "react-icons/fa"

const Contact = () => {
  return (
    <HStack divider={<StackDivider borderColor="gray.300" />}>
      <Flex
        as={Link}
        href="tel:+1-703-895-5538"
        align="center"
        p={2}
        borderRadius="4px"
        _hover={{ textDecor: "none", bgColor: "gray.300" }}
        isExternal
      >
        <Icon aria-label="Call us" as={FaPhone} color="teal.500" />
        <Text
          fontSize="xs"
          fontWeight="semibold"
          pl={2}
          display={{ base: "none", sm: "inline-flex" }}
        >
          Call Us
        </Text>
      </Flex>
      <Flex
        as={GatsbyLink}
        to="/contact"
        align="center"
        p={2}
        borderRadius="4px"
        _hover={{ textDecor: "none", bgColor: "gray.300" }}
      >
        <Icon color="teal.500" aria-label="Email us" as={FaEnvelope} />
        <Text
          fontSize="xs"
          fontWeight="semibold"
          pl={2}
          display={{ base: "none", sm: "inline-flex" }}
        >
          Email Us
        </Text>
      </Flex>
    </HStack>
  )
}

export default Contact

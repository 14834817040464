/* Footer social buttons */

import {
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  Stack,
} from "@chakra-ui/react"
import React from "react"
import { FaFacebook, FaInstagram } from "react-icons/fa"

const FollowUs = () => {
  return (
    <Stack>
      <Heading size="lg">Follow Us</Heading>
      <Divider borderWidth="1px" borderColor="teal" />
      <HStack spacing={2}>
        <IconButton
          as={Link}
          icon={<FaFacebook />}
          colorScheme="yellow"
          href="https://www.facebook.com/sherpahomeimprovement"
          aria-label="Facebook profile"
          isExternal
        />
        <IconButton
          as={Link}
          icon={<FaInstagram />}
          colorScheme="yellow"
          href="https://www.instagram.com/sherpahomeimprovement"
          aria-label="Instagram profile"
          isExternal
        />
      </HStack>
    </Stack>
  )
}

export default FollowUs

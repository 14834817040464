/* Footer quick links */

import { Divider, Heading, Link, Stack } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"

const QuickLinks = () => {
  return (
    <Stack mb={[4, 4, 0]}>
      <Heading size="lg">Quick Links</Heading>
      <Divider borderWidth="1px" borderColor="teal" />
      <Link as={GatsbyLink} to="/about">
        About
      </Link>
      <Link as={GatsbyLink} to="/services">
        Services
      </Link>
      <Link as={GatsbyLink} to="/projects">
        Projects
      </Link>
      <Link as={GatsbyLink} to="/testimonials">
        Testimonials
      </Link>
    </Stack>
  )
}

export default QuickLinks

/* Navigation bar */

import { Flex } from "@chakra-ui/react"
import React from "react"
import MainNav from "./mainbar"
import TopNav from "./topbar"

const Navbar = () => {
  return (
    <Flex direction="column" w="100%">
      <TopNav />
      <MainNav />
    </Flex>
  )
}

export default Navbar

/* Navbar link component */

import { Button, useBreakpointValue, useMediaQuery } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"

const NavLink = ({ linkName, to, onClick }) => {
  const btnSize = useBreakpointValue(["sm", "sm", "sm", "md"])
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)")

  return linkName === "Home" ? (
    <Button
      as={GatsbyLink}
      to="/"
      my={{ base: 1, md: 0 }}
      ml={{ base: 0, md: 2, lg: 4 }}
      size={btnSize}
      isFullWidth={isLargerThan480 ? false : true}
      onClick={onClick}
      getProps={({ isCurrent }) => ({
        style: {
          color: isCurrent ? "#234e52" : "#285e61",
          backgroundColor: isCurrent ? "#ecc94b" : "#edf2f7",
        },
      })}
    >
      {linkName}
    </Button>
  ) : (
    <Button
      as={GatsbyLink}
      to={to}
      my={{ base: 1, md: 0 }}
      ml={{ base: 0, md: 2, lg: 4 }}
      size={btnSize}
      isFullWidth={isLargerThan480 ? false : true}
      onClick={onClick}
      getProps={({ isPartiallyCurrent }) => ({
        style: {
          color: isPartiallyCurrent ? "#234e52" : "#285e61",
          backgroundColor: isPartiallyCurrent ? "#ecc94b" : "#edf2f7",
        },
      })}
    >
      {linkName}
    </Button>
  )
}

export default NavLink

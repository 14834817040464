/* Top bar - Business hours */

import { Icon, Stack, Text } from "@chakra-ui/react"
import React from "react"
import { FaRegClock } from "react-icons/fa"

const Hours = () => {
  return (
    <Stack
      direction={{ base: "column", sm: "row" }}
      justify="center"
      align="center"
      spacing={[0, 2, 4]}
    >
      <Text fontSize="xs" fontWeight="semibold">
        Mon - Fri
      </Text>
      <Icon as={FaRegClock} color="teal" />
      <Text fontSize="xs" fontWeight="semibold">
        8 AM - 6 PM
      </Text>
    </Stack>
  )
}

export default Hours

/* Footer contact info */

import {
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { FaEnvelope, FaPhone } from "react-icons/fa"

const ContactInfo = () => {
  return (
    <Stack mb={[4, 4, 0]}>
      <Heading size="lg">Contact Us</Heading>
      <Divider borderWidth="1px" borderColor="teal" />
      <Link href="tel:+1-703-895-5538" isExternal>
        <Flex align="center">
          <Icon as={FaPhone} mr={4} />
          <Text>703-895-5538</Text>
        </Flex>
      </Link>
      <Link as={GatsbyLink} to="/contact">
        <Flex align="center">
          <Icon as={FaEnvelope} mr={4} />
          <Text>Email</Text>
        </Flex>
      </Link>
    </Stack>
  )
}

export default ContactInfo

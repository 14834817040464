/* Footer component */

import { Container, Flex, Text } from "@chakra-ui/react"
import React from "react"
import BusinessHours from "./business-hours"
import ContactInfo from "./contact-info"
import FollowUs from "./follow-us"
import QuickLinks from "./quick-links"

const Footer = () => {
  return (
    <Flex
      direction="column"
      bgColor="gray.700"
      color="whitesmoke"
      justify="center"
      align="center"
      w="100%"
      py={4}
    >
      <Container maxW="container.xl" mb={4}>
        <Flex direction={["column", "column", "row"]} justify="space-between">
          <QuickLinks />
          <ContactInfo />
          <BusinessHours />
          <FollowUs />
        </Flex>
      </Container>
      <Text fontSize="sm">
        © {new Date().getFullYear()} Sherpa Home Improvement Inc. LLC
      </Text>
    </Flex>
  )
}

export default Footer

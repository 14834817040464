/* Footer business hours */

import { Divider, Flex, Heading, Stack, Text } from "@chakra-ui/react"
import React from "react"

const BusinessHours = () => {
  return (
    <Stack mb={[4, 4, 0]}>
      <Heading size="lg">Business Hours</Heading>
      <Divider borderWidth="1px" borderColor="teal" />
      <Flex>
        <Text minW="80px">Mon - Fri: </Text>
        <Text>8 AM - 6 PM</Text>
      </Flex>
      <Flex>
        <Text minW="80px">Sat - Sun: </Text>
        <Text>CLOSED</Text>
      </Flex>
    </Stack>
  )
}

export default BusinessHours

/* Scroll-to-top of page button */

import { Icon, VisuallyHidden } from "@chakra-ui/react"
import React from "react"
import { FaArrowCircleUp } from "react-icons/fa"
import ScrollToTop from "react-scroll-up"

const Button = () => {
  return (
    <ScrollToTop showUnder={500}>
      <Icon
        as={FaArrowCircleUp}
        w={10}
        h={10}
        color="yellow.500"
        _hover={{ color: "yellow.600" }}
      />
      <VisuallyHidden>Scroll to top of page</VisuallyHidden>
    </ScrollToTop>
  )
}

export default Button

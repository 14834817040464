/* Top bar - Facebook & Instagram links */

import {
  HStack,
  IconButton,
  Link,
  StackDivider,
  VisuallyHidden,
} from "@chakra-ui/react"
import React from "react"
import { FaFacebook, FaInstagram } from "react-icons/fa"

const Social = () => {
  return (
    <HStack divider={<StackDivider borderColor="gray.300" />}>
      <Link
        alignContent="center"
        href="https://www.facebook.com/sherpahomeimprovement"
        isExternal
      >
        <IconButton
          colorScheme="teal"
          size="xs"
          icon={<FaFacebook />}
          aria-label="Facebook profile link"
        />
        <VisuallyHidden>Facebook profile link</VisuallyHidden>
      </Link>
      <Link
        href="https://www.instagram.com/sherpahomeimprovement"
        aria-label="Instagram profile"
        isExternal
      >
        <IconButton
          colorScheme="teal"
          size="xs"
          icon={<FaInstagram />}
          aria-label="Instagram profile link"
        />
        <VisuallyHidden>Instagram profile link</VisuallyHidden>
      </Link>
    </HStack>
  )
}

export default Social

/* Navigation - Top bar */

import { Container, Flex } from "@chakra-ui/react"
import React from "react"
import Contact from "./contact"
import Hours from "./hours"
import Social from "./social"

const TopNav = () => {
  return (
    <Flex color="gray.700" bg="gray.200" py={[1, 2]}>
      <Container maxW="container.lg">
        <Flex justify="space-between" align="center">
          <Contact />
          <Hours />
          <Social />
        </Flex>
      </Container>
    </Flex>
  )
}

export default TopNav

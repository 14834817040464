/* Shadowing chakra-ui theme */

import { extendTheme } from "@chakra-ui/react"

const theme = {
  fonts: {
    heading: "Montserrat",
    body: "Source Sans Pro",
    mono: "Source Sans Pro",
  },
  styles: {
    global: {
      body: {
        bg: "gray.50",
        color: "gray.900",
      },
      a: {
        _hover: {
          textDecoration: "none",
        },
        textUnderlineOffset: "2px",
      },
    },
    components: {
      Button: {
        baseStyle: {
          minWidth: "200px",
        },
      },
    },
  },
}

export default extendTheme(theme)

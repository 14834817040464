/* Main navigation bar */

import { Box, Flex, Icon } from "@chakra-ui/react"
import React from "react"
import { FaBars, FaWindowClose } from "react-icons/fa"
import NavBrand from "./brand"
import NavLink from "./link"

const NavMenu = props => {
  const [show, setShow] = React.useState(false)

  const toggleMenu = () => setShow(!show)

  return (
    <Flex justify="center" bg="gray.50" color="teal.500" p={2}>
      <Flex
        align="center"
        justify="space-between"
        wrap="wrap"
        w="100%"
        {...props}
      >
        <NavBrand />
        <Box
          display={{ base: "flex", md: "none" }}
          alignItems="center"
          onClick={toggleMenu}
        >
          {show ? (
            <Icon as={FaWindowClose} color="teal" w={6} h={6} />
          ) : (
            <Icon as={FaBars} color="teal" w={6} h={6} />
          )}
        </Box>
        <Box
          display={{ base: show ? "block" : "none", md: "block" }}
          flexBasis={{ base: "100%", md: "auto" }}
        >
          <Flex
            align="center"
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "row", "row", "row"]}
            my={[2, 2, 3, 4]}
          >
            <NavLink to="/" linkName="Home" onClick={toggleMenu} />
            <NavLink to="/about" linkName="About" onClick={toggleMenu} />
            <NavLink to="/services" linkName="Services" onClick={toggleMenu} />
            <NavLink to="/projects" linkName="Projects" onClick={toggleMenu} />
            <NavLink
              to="/testimonials"
              linkName="Testimonials"
              onClick={toggleMenu}
            />
            <NavLink
              to="/contact"
              isLast
              linkName="Contact"
              onClick={toggleMenu}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default NavMenu

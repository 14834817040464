/* Navbar component with company icon and name */

import { HStack, Link, Text, useMediaQuery } from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const NavBrand = () => {
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)")

  const iconWidth = isLargerThan1280 ? 80 : 40
  const iconHeight = isLargerThan1280 ? 80 : 40

  return (
    <Link
      as={GatsbyLink}
      to="/"
      _hover={{ textDecor: "none" }}
      fontWeight="semibold"
      color="teal.700"
    >
      <HStack spacing={2}>
        <StaticImage
          src="../../../images/logo.png"
          alt="Sherpa Home Improvement company logo"
          style={{ maxWidth: iconWidth, maxHeight: iconHeight }}
        />
        <Text fontSize={isLargerThan1280 ? "xl" : "md"}>
          Sherpa Home Improvement
        </Text>
      </HStack>
    </Link>
  )
}

export default NavBrand

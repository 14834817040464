/* Base page layout */

import { Flex } from "@chakra-ui/react"
import "@fontsource/montserrat"
import "@fontsource/source-sans-pro"
import React from "react"
import Footer from "./footer"
import Navbar from "./navbar"
import ScrollToTopButton from "./scroll-to-top-button"

const Layout = ({ children }) => {
  return (
    <Flex direction="column" minH="100vh">
      <Flex as="nav" shrink={0}>
        <Navbar />
      </Flex>
      <Flex as="main" direction="column" grow={1}>
        <Flex direction="column" w="100%">
          {children}
        </Flex>
      </Flex>
      <ScrollToTopButton />
      <Flex as="footer" shrink={0}>
        <Footer />
      </Flex>
    </Flex>
  )
}

export default Layout
